@import './config.scss';
@import 'material-symbols';
@import 'material-symbols/outlined.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(../assets/fonts/Montserrat/Montserrat-Light.ttf) format('truetype');
    font-weight: 100;
}


/* estilos iconos */

.material-symbols-outlined {
    vertical-align: middle;
    font-variation-settings:
    'FILL' 0,
    'wght' 100,
    'GRAD' 0,
    'opsz' 24
  }

  /*estilos body*/
body {
    background-color: $color-disabled;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: $color-font-text;
}

/*estilos login*/
.bg-img{
     min-width: 300px;
     width: 100%;
     min-height: 300px;
     height: 90vh;
     background-color: #FFFFFF;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center
 
 }
 .login-container {
     margin-top: -5%;
     color: #999C9E;
 } 
 
 .lg-c {
     display: flex;
     flex-direction: column;
 }
 
.login-link {
    color: $color-neutro;
    font-size: 10px;
    text-decoration: none;
    margin-top: -5px;
    margin-bottom: 15px;
}

.login-link:hover {
    color: $color-font-primary;
}

.lg-btn{
    display: flex;
    justify-content: center;
}



input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $color-font-text;
  -webkit-box-shadow: 0 0 0px;
  transition: background-color 5000s ease-in-out 0s;
}

/*estilos header*/
.navbar{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    background-color: #FFFFFF;
    padding-top: 0 !important;
}

.navbar-routes {
    width: 100%;
}

.navbar>.container-fluid {
    padding-right: 0px;
}

.navlogo {
    height: 35px;
    width: 90px;
    margin: 0px 50px 0px 5px;
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
    cursor:pointer;
}

 .nav-link {
    color: $color-font-primary;
}

.nav-link:hover {
    font-weight: 600;
}
.nav-link:focus {
    color: $color-font-primary;
}
.nav-link.active, .navbar-nav .show>.nav-link {
    color: $color-font-primary !important;
    font-weight: 600;
}

.login-nav{
    justify-content: flex-end;
}

.nav-link-login {
    color: $color-neutro-icons;
    padding-left: 0.5rem
}

.nav-link-login:hover {
    color: $color-font-primary;
}

.nav-icon{
    height: 14px;
    width: 14px;
    margin-bottom: 5px;
}



/*estilos sub-cabecera*/

.section-heading {
    height: 28px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.text-heading-selecction {
    color: $color-font-text;
    font-weight: 600;
}

.hr-heading{
    color: $color-neutro;
}
.heading-subtitle {
    display: flex;
    align-items: center;
    margin-left: 15px !important;
}
.heading-subtitle > .material-symbols-outlined {
    color: $color-font-primary;
    margin-right: 13px;
    font-size: 28px;
    font-variation-settings:
    'wght' 300,
}

.heading-subtitle > .material-symbols-outlined:hover,  .heading-subtitle > .material-symbols-outlined:focus
.heading-subtitle > .material-symbols-outlined:active {
    color: $color-font-text;
}

.heading-modulo {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-right: 15px !important;
}
.navbar-brand {
    color: $color-font-primary;
    font-size: 14px;
    font-weight: 600;
    margin-right: 0px;
}
.navbar-brand:hover {
    color: $color-font-text;
}


/*estilos seccion acordeon*/

.section-accordion {
    background-color: #FFFFFF;
    border: 1px solid $color-li-neutro;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 15px;
    margin-bottom: 100px;
    color: $color-font-primary;
}
.accordion-header {
    border-bottom: 1px solid $color-li-neutro;
    width: 100%;
    height: 40px;
    cursor: pointer;
}
.accordion-header-title {
    display: flex;
    align-items: center;
    height: 40px;
}
.accordion-header-title > i {
    font-size: 20px;
    font-weight: 600;
    color: $color-font-primary;
    padding-left: 20px;
    padding-right: 5px;
}
.accordion-header-title > h3 {
    cursor: pointer;
}
.accordion-header-title > h3:hover {
    font-weight: 600;
}
.accordion-header-title > h3.active {
    font-weight: 600;
}
.accordion-body {
    width: 100%;
}
.accordion-body {
    padding: 1rem 1.25rem;
}
.accordion-hide {
    height: 0px;
    padding: 0 0 0 0;
    contain: content;
}


/*estilos de formularios*/

.form-basic > div {
    margin-top: 5px;
    margin-bottom: 5px;
}
.form-basic > div.form-check {
    // margin-left: 12px;
    padding-top: 28px;
}
div.form-check {
    padding-left: 30px;
}
.form-sub-component {
    text-indent: 0px;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 5px;
    padding-bottom: 30px;
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: $color-li-neutro;
}
.form-sub-component .form-label, .form-sub-component .form-check-label {
    font-size: 15px;
    color: #999C9E;
}

/*estilos generales*/
.label-error {
    color: $color-error;
    opacity: unset;
}

hr{
    color: $color-li-neutro;
    opacity: unset;
    border: none;
    margin:0;
}

h2 {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: $color-font-primary;
}
h3 {
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    color: $color-font-primary;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

.space {
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

/*estilos para botones*/

.btn {
    box-shadow: none !important;
}

.back-button {
    color: $color-neutro;
    border-color: $color-neutro;
}

.back-button:hover, .back-button:focus, .back-button:active,
.back-button:first-child:active, 
.back-button:not(.btn-check) + .back-button:active  {
    background-color: $color-neutro;
    color: #FFFFFF;
}

.f-align{
    display: flex;
    justify-content: flex-start !important;
}

.disp-b {
    display: inline-flex;
    justify-content: space-between !important;
}

.action-button {
    color: $color-action;
    border-color: $color-action;
    
}

.action-button:hover, .action-button:focus, 
.action-button:first-child:active, .action-button:focus-visible,
.action-button:focus-visible, :not(.btn-check)+.btn:active,
 :not(.btn-check) + .btn:active{
    color: #FFFFFF;
    background-color: $color-action;
    border-color: $color-action;
}

.action-button > .material-symbols-outlined {
    font-variation-settings:'wght' 300,
}

.action-button-fill {
    color: #FFFFFF;
    background-color: $color-action;
    border-color: $color-action;
}

.action-button-fill:hover, .action-button-fill:focus, 
.action-button-fill:first-child:active, .action-button-fill:focus-visible,
.action-button-fill:focus-visible, :not(.btn-check) + .btn:active {
    color: $color-action;
    border-color: $color-action;
    background-color: transparent;
}

.btn-primary, .btn-primary:hover,.btn-primary:focus,
.btn-primary:active, .btn-primary:focus-visible, 
.btn-primary:not(.btn-primary-check)+.btn-primary:active {
    color: #FFFFFF;
    background-color: $color-success !important;
    border-color: $color-success !important;
}

.btn-outline-primary, .btn-outline-primary:hover, 
.btn-outline-primary:focus, .btn-outline-primary:active,
.btn-outline-primary:first-child:active {
    color: $color-success;
    border-color: $color-success;
    background-color: #FFFFFF;
}

.btn-main {
    color: #FFFFFF;
    background-color: $color-neutro;
    border-color: $color-neutro;
    align-items: center;
}

.btn-main:hover, .btn-main:active {
    color: $color-neutro;
    background-color: #ffffff;
    border-color: $color-neutro;
}

.btn-disabled {
    color: $color-li-neutro !important;
    background-color: #ffffff !important;
    border-color: $color-li-neutro !important;
}

.invisible {
    display: none;
}

.decline-button {
    color: $color-error;
    border-color: $color-error;
}

.decline-button:hover, .decline-button:focus {
    background-color: $color-error;
    color: #FFFFFF;
}


/*estilos para links*/
a.nav-link, div.nav-link {
    cursor: pointer;
}
.link {
    cursor: pointer;
    color: $color-font-primary;
}
.link:hover{
    color: $color-font-text;
}

.link > .material-symbols-outlined {
    text-indent: 0px !important;
}




/*estilos de loading*/
.loading {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: $color-bg-block;
    position: fixed;
    z-index: 9990;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading img {
    width: 80px;
    height: 80px;
}
.loading-show {
    opacity: 1;
    height: 100vh
}
.loading-hide {
    opacity: 0;
    height: 0vh
}


/*estilos de popup modal*/
.modal {
    display: block !important;
    background-color: #FFFFFF;
    border: 1px solid $color-neutro;
    color: $color-font-primary;
    scrollbar-width: thin;
}
.modal-header{
    border-bottom:1px solid $color-li-neutro;
}
.modal-footer {
    border-top: 0px;
}

.modal-footer > .back-button {
    justify-content: flex-start;
}

.modal-icon {
    font-size: 40px !important;
    opacity: 0.8;
    font-variation-settings: 
    'FILL' 0,
    'wght' 100
}
.modal-block {
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: $color-bg-block;
    position: fixed;
    z-index: 9900;
    display: flex;
}
.modal-dialog-size {
    max-width: 800px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 4px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

::-webkit-scrollbar-thumb {
    background-color: $color-font-primary;
    outline: 1px solid $color-font-primary;
}

input::placeholder {
    opacity: 0.5 !important;
}

.display-row {
    display: flex;
    justify-content: flex-start;
}

.footer-center {
    justify-content: center;
}

.form-control{
    font-size: 14px;
    color: $color-font-text;
    border: 1px solid $color-inputs-borders;
  //  background-color: transparent !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:active:hover:not([disabled]), .form-control:focus{
    font-size: 14px;
    border-color: $color-neutro;
    color: $color-font-text;
    box-shadow: 0 0px 0px;
    outline: 0 none;
  //  background-color: transparent !important;
}

.form-control.force-disabled,
.form-control:disabled {
    color: $color-font-primary;
    background-color: $color-disabled !important;
    box-shadow: 0 0px 0px;
}


div.img-foto {
    position: relative;
}

div.img-foto img {
    max-height: 170px;
    cursor: pointer;
}

div.img-foto:hover div.link span.material-symbols-outlined {
    display: block !important;
}

div.img-foto div.link span.material-symbols-outlined {
    position: absolute;
    top: 0px;
    left: 15px;
    font-size: 20px;
}

// estilos footer

.footer{
    display: flex;
    align-items: center;
    background-color: $color-neutro;
    color: $color-li-neutro;
    z-index: 1000;
}


.footer-action {
    height: 60px;
    background-color: #FFFFFF !important;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    border-top: 1px solid $color-li-neutro;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
    margin-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
}
.footer-action-container {
    width: 100%;
}

.form-check-input:checked, .form-check-input:focus {
    background-color: $color-font-primary;
    border-color: $color-font-primary;
    box-shadow: 0 0 0;
}

.align-left {
    text-align: left!important;
}
.align-center {
    text-align: center!important;
}
.align-right {
    text-align: right!important;
    padding-right: 10px;
}

.no-p-horizontal {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.no-m-horizontal {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.no-m-left {
    margin-left: 0px !important;
}
.no-m-right {
    margin-right: 0px !important;
}

/*estilos para margin y padding en pixeles*/
.p-left-10 {
    padding-left: 10px !important;
}
.p-left-20 {
    padding-left: 20px !important;
}
.p-left-50 {
    padding-left: 50px !important;
}
.p-right-5 {
    padding-right: 5px !important;
}
.p-right-10 {
    padding-right: 10px !important;
}
.p-right-20 {
    padding-right: 20px !important;
}
.p-right-50 {
    padding-right: 50px !important;
}
.p-bottom-20 {
    padding-bottom: 20px !important;
}
.p-bottom-5 {
    padding-bottom: 5px !important;
}
.p-top-15 {
    padding-top: 15px !important;
}
.p-top-5 {
    padding-top: 5px !important;
}
.p-top-10 {
    padding-top: 10px !important;
}
.p-top-25 {
    padding-top: 25px !important;
}
.p-top-35 {
    padding-top: 35px !important;
}

.m-left-5 {
    margin-left: 5px !important;
}
.m-left-10 {
    margin-left: 10px !important;
}
.m-left-15 {
    margin-left: 15px !important;
}

.m-left-17 {
    margin-left: 17px !important;
}
.m-left-20 {
    margin-left: 20px !important;
}
.m-left-50 {
    margin-left: 50px !important;
}
.m-right-5 {
    margin-right: 5px !important;
}
.m-right-10 {
    margin-right: 10px !important;
}
.m-right-20 {
    margin-right: 20px !important;
}

.m-top-5 {
    margin-top: 5px !important;
}
.m-top-10 {
    margin-top: 10px !important;
}
.m-top-15 {
    margin-top: 15px !important;
}
.m-top-20 {
    margin-top: 20px !important;
}
.m-top-25 {
    margin-top: 25px !important;
}
.m-top-27 {
    margin-top: 27px !important;
}
.m-top-30 {
    margin-top: 30px !important;
}
.m-top-32 {
    margin-top: 32px !important;
}
.m-top-40 {
    margin-top: 40px !important;
}
.m-top-50 {
    margin-top: 50px !important;
}
.m-top-100 {
    margin-top: 100px !important;
}
.m-bottom-10 {
    margin-bottom: 10px !important;
}
.m-bottom-20 {
    margin-bottom: 20px !important;
}
.m-bottom-50 {
    margin-bottom: 50px !important;
}
.m-bottom-75 {
    margin-bottom: 75px !important;
}

.m-tp-25 {
    margin-top: -25px !important;
}

/*estilos para width en porcentajes*/
.width-5p {
    width: 5% !important;
}
.width-10p {
    width: 10% !important;
}
.width-15p {
    width: 15% !important;
}
.width-20p {
    width: 20% !important;
}
.width-25p {
    width: 25% !important;
}
.width-30p {
    width: 30% !important;
}
.width-35p {
    width: 35% !important;
}
.width-40p {
    width: 40% !important;
}
.width-45p {
    width: 45% !important;
}
.width-50p {
    width: 50% !important;
}
.width-60p {
    width: 60% !important;
}

.width-100p {
    width: 100%;
}

.tooltip {
    background-color: $color-background;
    position: absolute;
    visibility: hidden;
    opacity: 0%;
    padding: 3px 5px 3px 5px;
    font-size: 12px;
    color: $color-font-primary;
}

.tooltip-container:hover .tooltip {
    visibility: visible;
    opacity: 100%;
    transition: opacity 0.1s;
    transition-delay: 0.5s;
}

// estilos notificaciones - Toastify 

.Toastify__toast {
    font-family: 'Montserrat', sans-serif !important;
}

.Toastify__toast--warning {
    background: $color-warning !important;
  }

.Toastify__toast--success {
    background: $color-success !important;
  }

.Toastify__toast--info {
background: $color-action !important;
}

.Toastify__toast--error {
    background: $color-error !important;
    }


