
.navbar{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.navbar-routes {
    width: 100%;
}

.logo{
    background-image: url('../../../assets/images/LogoMunicipio.png');
}
.logo:hover {
    background-image: url('../../../assets/images/LogoMunicipioColor.png');
}