@import '../../styles/config.scss';

.login-container > .ingreso_container {
    position: absolute;
    top: 20%;
    left: calc(50% - 300px);
    width: 600px;
    align-items: center;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    padding: 15px;
    text-align: center;
}


.ingreso-icon{
    height: 145px;
    width: 145px;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: 20px;
    margin-right: 20px;
    background-size: cover;
    transition: background-image 0.3s ease-in-out;
}

.conf-icon{
    background-image: url('../../assets/images/Configuracion.png');
}
.conf-icon:hover {
    background-image: url('../../assets/images/ConfiguracionHover.png');
}

.adm-icon{
    background-image: url('../../assets/images/Administracion.png');
}
.adm-icon:hover{
    background-image: url('../../assets/images/AdministracionHover.png');
}
.ing-icon{
    background-image: url('../../assets/images/IngresosPublicos.png');
}
.ing-icon:hover{
    background-image: url('../../assets/images/IngresosPublicosHover.png');
}
.tes-icon{
    background-image: url('../../assets/images/Tesoreria.png');
}
.tes-icon:hover{
    background-image: url('../../assets/images/TesoreriaHover.png');
}

.auditoria-icon{
    background-image: url('../../assets/images/Auditoria.png');
}
.auditoria-icon:hover{
    background-image: url('../../assets/images/AuditoriaHover.png');
}


.ingreso-text {
    color: $color-font-text;
    font-size: 12px;
    font-weight: 800;
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
}

.mb-3 > a {
    color: $color-font-text;
    text-decoration: none;
}

.ingreso-container {
    background-color: #ffffff;
    padding: 5px;
    min-width: 500px;
    height: 100vh;
}

.ingreso {
    display: flex;
    justify-content: center;
    width: auto;
}

.ingreso > div {
    display: inline-flex;
    flex-direction: column;
}

.ingreso-menu > div {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ingreso-menu {
    background-color: #ffffff;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-bottom: 20%;
    margin-top: 10%;
}
