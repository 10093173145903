@import '../../../styles/config.scss';

.tab-carousel-outer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tab-carousel-inner-container {
    display: flex;
    align-items: center;
    z-index: 1;
    margin-bottom: -1px;
}

.tab-carousel-tabs {
    overflow: hidden;
    flex-wrap: nowrap;
    text-wrap: nowrap;
}

.tab-carousel-arrow {
    cursor: pointer;
}

.tab-carousel-arrow.disabled {
    display: none;
}

.tab-carousel-arrow:hover {
    font-weight: bolder;
}