
// Colors

// En caso de tener paleta crómatica usar:
//$color-primary: #;
//
$color-error: #CD1342;
$color-warning: #F8C04C;
$color-success: #24C9A7;
$color-action: #1C84C6;
$color-disabled: #FBFBFB;
$color-filter: #f9f3e9;


//Neutros
$color-li-neutro: #E2E2E2;
$color-neutro: #B3B4B6;
$color-bg-block: #676a6c6d;
$color-neutro-icons: #d9d9d9;
$color-background:#F3F3F3;
$color-inputs-borders:#ededed;

// Tipography and icon
$color-font-primary: #676A6C;
$color-font-text: #3e4041;


